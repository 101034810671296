import { Injectable } from "@angular/core";
import { HttpErrorResponse, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { Router } from "@angular/router";
import { BfcNotificationService, BfcNotificationType } from "@bfl/components/notification";
import { BfcTranslationService } from "@bfl/components/translation";

@Injectable({
  providedIn: "root",
})
export class ApiInterceptor implements HttpInterceptor {

  constructor(private router: Router,
    private bfcNotificationService: BfcNotificationService,
    private bfcTranslationService: BfcTranslationService) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<any> {
    return next.handle(request).pipe(
      catchError((err: unknown) => {
        if (err instanceof HttpErrorResponse) {
          if (err.status === 401 || err.status === 403) {
            this.router.navigate(["/unauthorized"]);
          }
          if (err.status === 406) {
            this.bfcNotificationService.showNotification({
              type: BfcNotificationType.ERROR,
              message: this.bfcTranslationService.translate("ERRORS." +
                      err.error.detail.error_label,
              ),
              options: { duration: 0 },
            });
          }
        }
        return throwError(err);
      }),
    );
  }
}
