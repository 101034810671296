import { Injectable } from "@angular/core";
import { BfcConfigurationService } from "@bfl/components/configuration";
import { HttpClient } from "@angular/common/http";
import { FotoApparatService } from "./foto-apparat.service";
import { DocumentMetaInfo } from "../generated/fotodocumentation-api/model/documentMetaInfo";

@Injectable()
export class DocumentService {

  constructor(
    private configurationService: BfcConfigurationService,
    private fotoApparatService: FotoApparatService,
    private httpClient: HttpClient) {
  }

  downloadDocument(documentList: DocumentMetaInfo[], selectedImageIndices:any[]) {
    let fileName;
    const uuidList = selectedImageIndices.map(document => document.uuid);
    if (selectedImageIndices.length === 1) {
      fileName = documentList.find(document=>document.uuid == selectedImageIndices[0]?.uuid)?.filename;
    } else {
      fileName = "documents";
    }
    this.fotoApparatService.downloadDocument(uuidList).subscribe(data => {
      const link = document.createElement("a");
      link.href = URL.createObjectURL(data);
      link.download = fileName ? fileName : null;
      link.target = "_blank";
      link.click();

    });
  }
}