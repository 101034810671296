import { Injectable } from "@angular/core";
import { CanActivate, Router, UrlTree, ActivatedRouteSnapshot } from "@angular/router";
import { Observable } from "rxjs";
import { AuthenticationService } from "./authentication.service";
import { BfcConfigurationService } from "@bfl/components/configuration";

@Injectable()
export class RoleGuard implements CanActivate {

  constructor(private router: Router, private authenticationService: AuthenticationService,
    private bfcConfigurationService: BfcConfigurationService) {
  }

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree>
  | boolean | UrlTree {
    const mustMatchAllRoles = route.data.mustMatchAllRoles;
    const mustMatchAnyRoles = route.data.mustMatchAnyRoles;

    let requiredRoles: string[] = [];
    if (mustMatchAnyRoles) {
      requiredRoles =  route.data.mustMatchAnyRoles.map(role=>{
        return this.bfcConfigurationService.configuration[role];
      });
      return this.authenticationService.isAuthorizedForAnyRole(requiredRoles).then((isAuthorized: boolean) => {
        if (!isAuthorized) {
          this.router.navigate(["unauthorized"]);
        }
        return isAuthorized;
      });
    } else if (mustMatchAllRoles) {
      requiredRoles =  route.data.mustMatchAllRoles.map(role=>{
        return this.bfcConfigurationService.configuration[role];
      });
      return this.authenticationService.isAuthorizedForAllRoles(requiredRoles).then((isAuthorized: boolean) => {
        if (!isAuthorized) {
          this.router.navigate(["unauthorized"]);
        }
        return isAuthorized;
      });
    }
  }
}