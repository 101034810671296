<div class="sidenav" *ngIf="!isMobile">
    <ng-container *ngFor="let navItems of navigationTree">
        <a [routerLink]="navItems.url.path" routerLinkActive="active">{{navItems.labelKey}}</a>
    </ng-container>
</div>
<div class="sidenav-mobile" *ngIf="isMobile">
    <div class="nav-container" (click)="toggleSideNav()">
        <span>Navigation</span>
        <bfe-icon *ngIf="isSideNavCollapsed" name="bfc-icon-caret-down" class="bfe-icon-24 bfc-margin-right"></bfe-icon>
        <bfe-icon *ngIf="!isSideNavCollapsed" name="bfc-icon-caret-up" class="bfe-icon-24 bfc-margin-right"></bfe-icon>
    </div>
    <div  [ngClass]="{'collapsed': isSideNavCollapsed}">
        <ng-container *ngFor="let navItems of navigationTree">
            <a [routerLink]="navItems.url.path" routerLinkActive="active">{{navItems.labelKey}}</a>
        </ng-container>
    </div>
</div>