import { Injectable } from "@angular/core";
import { BfcConfigurationService } from "@bfl/components/configuration";
import { HttpClient, HttpParams } from "@angular/common/http";
import { Observable } from "rxjs";
import { DocumentMetaInfo } from "../generated/fotodocumentation-api/model/documentMetaInfo";
import { Category } from "../generated/fotodocumentation-api/model/category";
import { InputSetNisNumber } from "../generated/fotodocumentation-api/model/inputSetNisNumber";
import { InputSetCategory } from "../generated/fotodocumentation-api/model/inputSetCategory";
import { NisNumberWithDistance } from "../generated/fotodocumentation-api/model/nisNumberWithDistance";
import { Group } from "../generated/fotodocumentation-api/model/group";
import { InputSetGroup } from "../generated/fotodocumentation-api/model/inputSetGroup";
import { Workflow } from "../settings/workflows/workflow.interface";

@Injectable()
export class FotoApparatService {

  constructor(
    private configurationService: BfcConfigurationService,
    private httpClient: HttpClient) {
  }

  getAllImages(nisNumber: string): Observable<DocumentMetaInfo[]> {
    const params: HttpParams = new HttpParams()
      .set("nis_number", nisNumber);

    const url = `${this.configurationService.configuration.apiUrl}/document/`;

    return this.httpClient.get<DocumentMetaInfo[]>(url, { params });
  }

  uploadImage(jsonBody: any): Observable<any> {
    const url = `${this.configurationService.configuration.apiUrl}/document/`;

    return this.httpClient.put<any>(url, jsonBody );
  }

  createVirtualAsset(asset): Observable<any> {
    let params: HttpParams = new HttpParams();
    params = params.set("name", asset.name);
    params = params.set("lat", asset.latitude);
    params = params.set("long", asset.longitude);

    const url = `${this.configurationService.configuration.apiUrl}/nis_number/`;

    return this.httpClient.put<any>(url, null, { params });
  }

  deleteVirtualAsset(nis_number: string[]): Observable<any> {
    const url = `${this.configurationService.configuration.apiUrl}/nis_number/`;
    const options = {
      body: nis_number,
    };
    return this.httpClient.delete(url, options);
  }

  createGroup(name:string): Observable<any> {
    let params: HttpParams = new HttpParams();
    params = params.set("group_name", name);
    const url = `${this.configurationService.configuration.apiUrl}/group/`;

    return this.httpClient.put<any>(url, null, { params });
  }

  getNisElementList(nameString?: string, lat?: number, long?: number, nearbyAssetsOnly?: boolean) {
    let params: HttpParams = new HttpParams();
    if (nameString) {
      params = params.set("substr", nameString);
    }
    if (lat) {
      params = params.set("lat", lat);
    }
    if (long) {
      params = params.set("long", long);
    }
    if (nearbyAssetsOnly) {
      params = params.set("near_by_objects_only", nearbyAssetsOnly);
    }
    const url = `${this.configurationService.configuration.apiUrl}/nis_number/`;
    return this.httpClient.get<NisNumberWithDistance[]>(url, { params:params });
  }

  getNisElementByString(nameString: string): Observable<NisNumberWithDistance[]>{
    const params: HttpParams = new HttpParams()
      .set("substr", nameString);
    const url = `${this.configurationService.configuration.apiUrl}/nis_number/search_by_substring`;
    return this.httpClient.get<NisNumberWithDistance[]>(url, { params:params });
  }

  getNisElementNearby(lat: number, long: number): Observable<NisNumberWithDistance[]>{
    const params: HttpParams = new HttpParams()
      .set("lat", lat)
      .set("long", long);
    const url = `${this.configurationService.configuration.apiUrl}/nis_number/search_by_coordinate`;
    return this.httpClient.get<NisNumberWithDistance[]>(url, { params:params });
  }

  getThumbnail(uuid:string): Observable<Blob> {
    const url = `${this.configurationService.configuration.apiUrl}/thumbnail/${uuid}`;
    return this.httpClient.get(url, { responseType:"blob" });
  }

  deleteDocument(uuidList: string[]): Observable<any> {
    const url = `${this.configurationService.configuration.apiUrl}/document/`;
    const options = {
      body: uuidList,
    };
    return this.httpClient.delete(url, options);
  }

  downloadDocument(uuidList: string[]): Observable<Blob> {
    const url = `${this.configurationService.configuration.apiUrl}/document/`;
    return this.httpClient.post(url, uuidList, { responseType:"blob" });
  }

  getAllDocuments(nis_number:string, documentNameString?:string): Observable<DocumentMetaInfo[]>{
    let params: HttpParams = new HttpParams()
      .set("nis_number", nis_number);
    if (documentNameString) {
      params = params.set("substr", documentNameString);
    }
    const url = `${this.configurationService.configuration.apiUrl}/document/`;
    return this.httpClient.get<DocumentMetaInfo[]>(url, { params });
  }

  getDocumentByUUID(uuid:string): Observable<Blob> {
    const url = `${this.configurationService.configuration.apiUrl}/document/${uuid}`;
    return this.httpClient.get(url, { responseType:"blob" });
  }

  getCategories(): Observable<Category[]>{
    const url = `${this.configurationService.configuration.apiUrl}/category/`;
    return this.httpClient.get<Category[]>(url);
  }

  getDescription(uuid: any): Observable<any>{
    const params: HttpParams = new HttpParams()
      .set("file_uuid", uuid);
    const url = `${this.configurationService.configuration.apiUrl}/description/`;
    return this.httpClient.get<any>(url, { params:params });
  }

  getGroups(): Observable<Group[]>{
    const url = `${this.configurationService.configuration.apiUrl}/group/`;
    return this.httpClient.get<Group[]>(url);
  }

  deleteGroup(id: number): Observable<void> {
    const url = `${this.configurationService.configuration.apiUrl}/group/`;
    const params: HttpParams = new HttpParams()
      .set("group_id", id);
    return this.httpClient.delete<void>(url, { params:params });
  }

  renameGroup(id: number, name:string): Observable<string> {
    const url = `${this.configurationService.configuration.apiUrl}/group/`;
    const params: HttpParams = new HttpParams()
      .set("group_id", id)
      .set("new_group_name", name);
    return this.httpClient.patch<string>(url, null, { params:params });
  }

  updateCategory(category:InputSetCategory): Observable<InputSetCategory> {
    const url = `${this.configurationService.configuration.apiUrl}/document/set_category`;
    return this.httpClient.patch<InputSetCategory>(url, category);
  }

  updateGroups(groups:InputSetGroup): Observable<InputSetGroup> {
    const url = `${this.configurationService.configuration.apiUrl}/document/set_group`;
    return this.httpClient.patch<InputSetGroup>(url, groups);
  }

  renameDocument(uuid:string, name:string): Observable<string> {
    const params: HttpParams = new HttpParams()
      .set("file_uuid", uuid)
      .set("new_name_without_extension", name);
    const url = `${this.configurationService.configuration.apiUrl}/document/rename_file`;
    return this.httpClient.patch<string>(url, null, { params:params });
  }

  editDescription(uuid:string, description:string): Observable<string> {
    const params: HttpParams = new HttpParams()
      .set("file_uuid", uuid);
    const url = `${this.configurationService.configuration.apiUrl}/document/set_description`;
    return this.httpClient.patch<string>(url, description, { params:params });
  }

  reassignDocument(nis_number:InputSetNisNumber): Observable<InputSetNisNumber> {
    const url = `${this.configurationService.configuration.apiUrl}/document/set_nis_number`;
    return this.httpClient.patch<InputSetNisNumber>(url, nis_number);
  }

  getWorkflows(): Observable<Workflow[]>{
    const url = `${this.configurationService.configuration.apiUrl}/workflow/`;
    return this.httpClient.get<Workflow[]>(url);
  }

  getWorkflow(id:string): Observable<Workflow>{
    const url = `${this.configurationService.configuration.apiUrl}/workflow/${id}`;
    return this.httpClient.get<Workflow>(url);
  }

  deleteWorkflow(id: number): Observable<void> {
    const url = `${this.configurationService.configuration.apiUrl}/workflow/${id}`;
    return this.httpClient.delete<void>(url);
  }

  createWorkflow(workflow:Workflow): Observable<Workflow> {
    const url = `${this.configurationService.configuration.apiUrl}/workflow/`;
    const params: HttpParams = new HttpParams()
      .set("name", workflow.name);
    return this.httpClient.put<Workflow>(url, workflow, { params: params });
  }

  updateWorkflow(id:string, workflow:Workflow): Observable<Workflow> {
    const url = `${this.configurationService.configuration.apiUrl}/workflow/`;
    const params: HttpParams = new HttpParams()
      .set("id", id)
      .set("name", workflow.name);
    return this.httpClient.patch<Workflow>(url, workflow, { params:params });
  }
}
