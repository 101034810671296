import { IStarterAppConfiguration } from "../configuration";
import { InteractionType } from "@azure/msal-browser";

export const P_ENV: IStarterAppConfiguration = {
  apiUrl: "https://fotodocumentation-api.bkw.ch/api/v1",
  fanBackendApiUrl: "https://api-fan.bkw.ch",

  adminRoleId: "e3e71d5b-38f1-4dc1-9411-fe27b821e102",
  fotografRoleId: "9972c02c-32c7-41f8-9823-387661098abd",

  msalAngularInterceptorConfig: {
    interactionType: InteractionType.Redirect,
    // array declaration as any is a workaround, because new Map() was recognized only as typeof object from msal
    protectedResourceMap: [
      ["https://graph.microsoft.com/*", ["user.read"]], // Microsoft Graph API
      ["https://fotodocumentation-api.bkw.ch/*",
        ["api://488d1aba-7fd8-4b46-8919-24089dd1fcbf/user_impersonation"]], // fotodocumentation-api
      ["https://api-fan.bkw.ch/*", ["api://3120c849-59b4-40da-bd06-6ab552662387/Read"]], // jad-fan-api
    ] as any,
  },
};
