export const DE_TRANSLATIONS = {
  FAN: {
    TITLE: "Fotoapparat Asset Netze",
    FP_MENU_TITLE: "Foto Apparat",
    FB_MENU_TITLE: "Foto Browser",
    SETTINGS_MENU_TITLE: "Einstellungen",
    NO_POSITION: "Kein Geo Information in Foto! Bitte wählen Sie ein anderes.",
    STANDARD_DOKUMENTATION_TITLE: "Standard Foto Dokumentation",
    OTHER_UPLOADS: "Sonstige Dokumente",
    REQUIRED_FIELD: "Dieses Feld ist erforderlich.",
    TRAFO_TITLE: "Trafostationen",
    VERTEIL_TITLE: "Verteilkabinen",
    TRAGWERK_TITLE: "Tragwerke",
    ADD: "Hinzufügen",
    PREVIOUS: "Vorherige",
    NEXT: "Nächste",
    SEARCH_DOCUMENT: "Dokumentname",
    CHOOSE_ON_MAP: "Karte auswahl",
    DIALOG: {
      SAVE: "Speichern",
      YES: "Ja",
      CANCEL: "Abbrechen",
      PREVIOUS: "Vorherige",
      NEXT: "Nächste",
      FINISH: "Fertig",
      START: "Start",
      CHOOSE: "Wählen",
      SELECTED_ASSET: "Ausgewählt Asset",
      CONFIRMATION_DIALOG_TEXT: "Sind Sie sicher, dass Sie das Dokument löschen möchten?",
      CONFIRMATION_DIALOG_TITLE: "Bestätigung",
    },
    VIRTUAL_ASSET: {
      TITLE: "Virtueller Asset erstellen",
      NAME: "Name",
      LONGITUDE: "Longitude",
      LATITUDE: "Latitude",
      SUCCESS_MESSAGE: "Das Asset wurde erfolgreich erstellt!",
      DELETE_TOOLTIP_ENABLED: "Virtuelles Asset löschen",
      DELETE_TOOLTIP_DISABLED: "Sie müssen zunächst alle Dokumente aus diesem Asset löschen",
      CONFIRMATION_DIALOG_TEXT: "Sind Sie sicher, dass Sie das Virtuel Asset löschen möchten?",
      SUCCESS_MESSAGE_DELETE: "Das Virtuell Asset wurde erfolgreich gelöscht!",
    },
    EDIT_DIALOG: {
      TITLE: "Bearbeiten",
    },
    SEND_LINK_DIALOG:{
      TITLE: "Link zu Dokumenten versenden",
      INPUT_EMAIL: "Email",
      INPUT_EMAIL_HINT: "Email Empfänger",
      INPUT_NOTE: "Bemerkung",
      INPUT_NOTE_HINT: "Bemerkung Email",
      BUTTON_SEND: "Senden",
    },
    STANDARD: {
      TITLE: "Auswahl",
      LABEL: "Netzobjekt",
    },
    CHOOSE_PHOTO: "Foto",
    SELECT_PHOTO: "Foto auswählen",
    REQUIRED: "Dieses Feld ist erforderlich.",
    UPLOAD: "Hochladen",
    UPLOAD_DOCUMENT: "Dokument hochladen",
    SUCCESS_MESSAGE: "Bild wurde erfolgreich hochgeladen!",
    ERROR_MESSAGE: "Beim Hochladen ist ein Fehler aufgetreten.",
    RENAME_ASSET: {
      LABEL_RENAME : "Name",
      HINT_RENAME : "Ohne Erweiterung umbenennen",
      TITLE: "Umbenennen",
    },
    ASSET_SELECTION : {
      LABEL_SELECT : "Assets",
      HINT_SELECT : "Asset auswählen",
    },
    GROUP_SELECTION : {
      LABEL_SELECT : "Gruppen",
      HINT_SELECT : "Gruppe auswählen",
      DIALOG_TITLE: "Gruppe erstellen",
      NAME_FIELD: "Name",
      FILTER_LABEL: "Gruppen filter",
      ADD: "Gruppe hinzufügen",
      CREATE_SUCCESS: "Die Gruppe wurde erflogreich erstellt!",
      RENAME_SUCCESS: "Die Gruppe wurde erflogreich bearbeitet!",
      DELETE_SUCCESS: "Die Gruppe wurde erflogreich gelöscht!",
      CONFIRMATION_DIALOG_TEXT: "Sind Sie sicher, dass Sie die Gruppe löschen möchten?",
      CONFIRMATION_DIALOG_TITLE: "Bestätigung",
    },
    WORKFLOWS : {
      LABEL_SELECT : "Workflows",
      HINT_SELECT : "Workflow auswählen",
      DIALOG_TITLE: "Workflow erstellen",
      NAME_FIELD: "Name",
      DESCRIPTION_FIELD:"Beschreibung",
      FILTER_LABEL: "Workflow filter",
      ADD: "Workflow hinzufügen",
      CREATE_SUCCESS: "Die Workflow wurde erflogreich erstellt!",
      EDIT_SUCCESS: "Die Workflow wurde erflogreich bearbeitet!",
      DELETE_SUCCESS: "Die Workflow wurde erflogreich gelöscht!",
      CONFIRMATION_DIALOG_TEXT: "Sind Sie sicher, dass Sie die Workflow löschen möchten?",
      CONFIRMATION_DIALOG_TITLE: "Bestätigung",
      STEP_TITLE: "Schritte",
      STEP_SUBTITLE: "Schritt",
      MOVE_UP: "Nach oben bewegen",
      MOVE_DOWN: "Nach unten bewegen",
      ADD_STEP: "Schritt hinzufügen",
      DELETE_STEP: "Schritt löschen",

    },
    CATEGORY_SELECTION : {
      LABEL_SELECT : "Kategorie",
      HINT_SELECT : "Kategorie auswählen",
      FILTER_LABEL: "Kategorien filter",
      SORTING_LABEL: "Sortierung",
      REQUIRED: "Bitte wählen Sie mindestens eine Kategorie aus.",
    },
    DESCRIPTION: {
      TITLE: "Kommentar hinzufügen",
    },
    TEMPORARY_DATE: {
      TITLE: "Temporäre Bilder",
      LABEL:"Gültigkeitsdauer",
      HINT: "Dokument als Temporär markieren",
      DIALOG_TOOLTIP: "Gültigkeitsdauer bearbeiten",
    },
    ACCESS_RESTRICTION: {
      LABEL:"Kommentar",
      HINT: "Eine Spezielle Zufahrtsbeschränkung",
    },
    COMMENT_DIALOG: {
      TITLE:"Kommentar",
      HINT: "Eine Spezielle Zufahrtsbeschränkung",
    },
    MESSAGE_TOOLTIP: "Link via Email versenden",
    COPY_TOOLTIP: "Link in die Zwischenablage kopieren",
    EDIT_TOOLTIP: "Bearbeiten",
    DELETE_TOOLTIP: "Löschen",
    DOWNLOAD_TOOLTIP: "Herunterladen",
    NO_FOTOS_FOUND: " Keine Fotos für dieses Element vorhanden.",
    SEARCH_ASSET: "Asset suchen",
    CHANGE_GROUP: "Gruppe zuweisen",
    NO_ASSET_FOUND: "Kein Asset gefunden",
    REASSIGN_ASSET: "Dokument neu zuweisen",
    BACK_TO_SEARCH: "Zurück zur Asset Suche",
    SUCCESS_MESSAGE_DELETE: "Dokument wurde erfolgreich gelöscht!",
    SUCCESS_MESSAGE_ASSIGN: "Dokument wurde erfolgreich zugeweisst!",
    SUCCESS_MESSAGE_EDIT: "Dokument wurde erfolgreich berabeitet!",
    SUCCESS_MESSAGE_MAIL: "Email wurde versendet!",
    ERROR_MESSAGE_MAIL: "Es ist ein Fehler aufgetreten beim versenden der Email!",
    CROPPER: {
      TITLE: "Foto bearbeiten",
      CANCEL: "Abbrechen",
      APPLY: "Übernehmen",
    },
  },

  GLOBAL_NAV: {
    LOGOUT: "Logout",
  },

  BFC_UNAUTHORIZED: {
    TITLE: "Kein Zugriff!",
    CONTENT: "Der Service steht Ihnen leider nicht zur Verfügung",
  },
  ERRORS: {
    INCORRECT_JSON_FORMAT_ERROR: "Die Eingabeparameter sind nicht korrekt. Bitte überprüfen Sie diese noch einmal.",
    ASSET_NOT_FOUND_ERROR: "Asset mit dieser NIS Nummer wurde nicht gefunden.",
    CATEGORY_NOT_FOUND_ERROR: "Die eingegebene Kategorie ist falsch.",
    FILE_ALREADY_EXISTS_ERROR: "Diese Datei existiert bereits.",
  },
};
