import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { BrowserModule, DomSanitizer } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import {
  BfcConfigurationModule,
  BFC_CONFIGURATIONS,
} from "@bfl/components/configuration";
import {
  BfcTranslationModule,
  BFC_TRANSLATIONS,
} from "@bfl/components/translation";
import { BfcBasePageLayoutModule } from "@bfl/components/base-page-layout";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { configuration } from "./config/configuration";
import { translations } from "./config/translations/translations";
import { CoreModule } from "./core/core.module";
import { CommonModule } from "@angular/common";
import { BfcLinkModule } from "@bfl/components/link";
import { BfcSinglePageLayoutModule } from "@bfl/components/single-page-layout";
import { BfcNotificationModule } from "@bfl/components/notification";
import { ReactiveFormsModule } from "@angular/forms";
import { BfcSinglePageLayoutLargeModule } from "@bfl/components/single-page-layout-large";
import { MatIconRegistry } from "@angular/material/icon";
import { BfcFormFieldModule } from "@bfl/components/form-field";
import { BfcInputModule } from "@bfl/components/input";
import { BfcFormModule } from "@bfl/components/form";
import { BfcCoreModule } from "@bfl/components/core";
import { MsalApplicationModule } from "./core/msal-application-module";
import { ApiInterceptor } from "./core/api-interceptor";
import { SideNavigationComponent } from "./side-navigation/side-navigation.component";
import { AuthenticationService } from "./core/roles/authentication.service";
import { RoleGuard } from "./core/roles/role-guard.service";

@NgModule({
  declarations: [AppComponent, SideNavigationComponent],
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    CoreModule,
    ReactiveFormsModule,
    BfcBasePageLayoutModule,
    BfcConfigurationModule,
    BfcCoreModule,
    BfcFormFieldModule,
    BfcFormModule,
    BfcInputModule,
    BfcLinkModule,
    BfcNotificationModule,
    BfcSinglePageLayoutModule,
    BfcSinglePageLayoutLargeModule,
    BfcTranslationModule,
    MsalApplicationModule,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ApiInterceptor,
      multi: true,
    },
    {
      provide: BFC_TRANSLATIONS,
      useValue: translations,
      multi: true,
    },
    {
      provide: BFC_CONFIGURATIONS,
      useValue: configuration,
      multi: true,
    },

    AuthenticationService,
    RoleGuard,
  ],
  schemas:[CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(matIconRegistry: MatIconRegistry, domSanitizer: DomSanitizer) {
    matIconRegistry.addSvgIcon("arrow-left", domSanitizer.bypassSecurityTrustResourceUrl(`assets/icons/arrow-left-blue.svg`));
    matIconRegistry.addSvgIcon("arrow-right", domSanitizer.bypassSecurityTrustResourceUrl(`assets/icons/arrow-right-blue.svg`));
    matIconRegistry.addSvgIcon("arrow-left-disabled", domSanitizer.bypassSecurityTrustResourceUrl(`assets/icons/arrow-left.svg`));
    matIconRegistry.addSvgIcon("arrow-right-disabled", domSanitizer.bypassSecurityTrustResourceUrl(`assets/icons/arrow-right.svg`));

  }
}
