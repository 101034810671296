import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { translations } from "./config/translations/translations";
import { MsalGuard } from "@azure/msal-angular";
import { RoleGuard } from "./core/roles/role-guard.service";
import { RoleEnum } from "./core/roles/role.enum";

const routes: Routes = [

  {
    path: "foto-apparat",
    canActivate: [MsalGuard, RoleGuard],
    data: { mustMatchAnyRoles: [RoleEnum.ROLE_ADMIN, RoleEnum.ROLE_FOTOGRAF] },
    loadChildren: () => import("./main-view/main-view.module").then(m => m.MainViewModule),
  },
  {
    path: "foto-browser",
    canActivate: [MsalGuard],
    loadChildren: () => import("./foto-browser/foto-browser.module").then(m => m.FotoBrowserModule),
  },
  {
    path: "settings",
    canActivate: [MsalGuard, RoleGuard],
    data: { mustMatchAllRoles: [RoleEnum.ROLE_ADMIN] },
    loadChildren: () => import("./settings/settings.module").then(m => m.SettingsModule),
  },
  {
    path: "unauthorized",
    loadChildren: () => import("@bfl/components/unauthorized").then(m => m.BfcUnauthorizedModule.asChild(translations)),
  },
  {
    path: "",
    pathMatch: "full",
    redirectTo: "foto-browser",
  },
  {
    path: "**",
    redirectTo: "",
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
