import { IStarterAppConfiguration } from "../configuration";
import { BrowserCacheLocation, InteractionType } from "@azure/msal-browser";

export const IS_IE = window.navigator.userAgent.indexOf("MSIE ") > -1 || window.navigator.userAgent.indexOf("Trident/") > -1;

export const GENERAL_ENV: IStarterAppConfiguration = {
  googleTagManagerContainerId: "GTM-N4VBJZH",
  msalConfig: {
    auth: {
      clientId: "5678fbb9-1645-4020-89c5-edb3d57c8311", // jad-fan
      authority: "https://login.microsoftonline.com/bkwfmbenergie.onmicrosoft.com",
      redirectUri: window.location.origin,
    },
    cache: {
      cacheLocation: BrowserCacheLocation.LocalStorage,
      storeAuthStateInCookie: IS_IE, // set to true for IE 11
    },
  },
  msalAngularGuardConfig: {
    interactionType: InteractionType.Redirect,
    authRequest: {
      scopes: [],
    },
    loginFailedRoute: "./unauthorized",
  },
};
