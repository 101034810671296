import { Injectable } from "@angular/core";
import { MsalService } from "@azure/msal-angular";
import { Observable, Subject } from "rxjs";
import { Client } from "@microsoft/microsoft-graph-client";
import { AccountInfo, AuthenticationResult } from "@azure/msal-browser";
import { GraphUserGroup } from "./graph-user.group";
import { UserGroup } from "./user-group";
import * as moment from "moment/moment";
import { RoleEnum } from "./role.enum";
import { BfcConfigurationService } from "@bfl/components/configuration";

@Injectable()
export class AuthenticationService {

  private isAuthorized_: boolean = false;

  isLoaded = new Subject<boolean>();

  constructor(private msalService: MsalService,
    private bfcConfigurationService: BfcConfigurationService) {
    this.isLoaded.next(false);
  }

  private getAuthenticationResult(): Observable<AuthenticationResult | void> {
    const account: AccountInfo = this.selectCurrentOrFirstAccount();

    // if account exists and token is not expired
    if (!!account && moment.unix(account.idTokenClaims.exp).isAfter(moment())) {
      const requestObj = {
        scopes: ["user.read"],
        account: account,
      };
      return this.msalService.acquireTokenSilent(requestObj);
    } else {
      const requestObj = {
        scopes: ["user.read"],
      };
      return this.msalService.acquireTokenRedirect(requestObj);
    }
  }

  // We are using async/await calls here as the underlying MSAL framework provides us only this kind of methods.
  // As we want to avoid a weird mix of observables and promises, we stick to the original implementation here
  private async getGraphClient(){
    return Client.init({
      authProvider: (done) => {

        this.getAuthenticationResult().subscribe(result => {
          if (result) {
            const token = result.accessToken;
            if (token) {
              done(null, token);
            } else {
              done("Could not get an access token", null);
            }
          }
        },
        () => {
          this.reloadPage();
        });
      },
    });
  }

  async isAuthorizedForAllRoles(requiredRoles: string[]): Promise<boolean> {
    const allGroups: UserGroup[] = await this.getGroups();
    this.isAuthorized_ = this.checkAuthorizationAll(allGroups, requiredRoles);

    return new Promise((resolve) => {
      resolve(this.isAuthorized_);
    });
  }

  async isAuthorizedForAnyRole(requiredRoles: string[]): Promise<boolean> {
    const allGroups: UserGroup[] = await this.getGroups();
    this.isAuthorized_ = this.checkAuthorizationAny(allGroups, requiredRoles);
    return new Promise((resolve) => {
      resolve(this.isAuthorized_);
    });
  }

  async hasAdminRole(): Promise<boolean> {
    return this.isAuthorizedForAllRoles([this.bfcConfigurationService.configuration[ RoleEnum.ROLE_ADMIN]]);
  }

  async hasFotografRole(): Promise<boolean> {
    return this.isAuthorizedForAllRoles([this.bfcConfigurationService.configuration[ RoleEnum.ROLE_FOTOGRAF]]);
  }

  private async getGroups(): Promise<UserGroup[]> {
    const graphClient = await this.getGraphClient();

    let allGroups = [];

    let graphUser = await graphClient.api("/me").get();
    let graphUserGroups: GraphUserGroup = await graphClient
      .api(`/users/${graphUser.id}/memberOf?$top=100`)
      .get();

    allGroups = graphUserGroups.value;

    // MS Graph Client only checks for the first 100 Roles. To check for all Roles we have to use @odata.nextLink
    // which is only provided when there is a next page. We can disable the linter here as we explicitly check
    // if the entity is present.
    let nextPageLink = graphUserGroups["@odata.nextLink"];
    while (nextPageLink !== undefined) {
      // eslint-disable-next-line @typescript-eslint/no-loop-func
      await graphClient.api(nextPageLink).get().then( (groupResults: GraphUserGroup) => {
        nextPageLink = groupResults["@odata.nextLink"];
        allGroups = allGroups.concat(groupResults.value);
      });
    }
    return allGroups;
  }

  private checkAuthorizationAll(userGroups: UserGroup[], requiredRoles: string[]): boolean {
    const normalizedUserGroups = userGroups.map(group => group.id);
    return requiredRoles.every(role => normalizedUserGroups.includes(role));

  }

  private checkAuthorizationAny(userGroups: UserGroup[], requiredRoles: string[]): boolean {
    const normalizedUserGroups = userGroups.map(group => group.id);
    return requiredRoles.some(role => normalizedUserGroups.includes(role));

  }

  private selectCurrentOrFirstAccount():AccountInfo{
    if (this.msalService.instance.getActiveAccount()){
      return this.msalService.instance.getActiveAccount();
    }
    return this.msalService.instance.getAllAccounts()[0];
  }

  private reloadPage(): void {
    setTimeout(function (){
      window.location.reload();
    }, 1500);
  }
}
