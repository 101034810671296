import { Inject, Injectable } from "@angular/core";
import { BfcConfigurationService } from "@bfl/components/configuration";
import { BfcGoogleAnalyticsService } from "@bfl/components/google-analytics";
import { BfcTranslationService } from "@bfl/components/translation";
import { MsalService } from "@azure/msal-angular";

@Injectable()
export class InitializerProvider {
  constructor(
    @Inject(BfcTranslationService) private bfcTranslateService: BfcTranslationService,
    @Inject(BfcGoogleAnalyticsService) private bfcGoogleAnalyticsService: BfcGoogleAnalyticsService,
    @Inject(BfcConfigurationService) private bfcConfigurationService: BfcConfigurationService,
    @Inject(MsalService) private msalService: MsalService,
  ) {}

  initialize(): void {
    this.bfcGoogleAnalyticsService.initGoogleTagManager(
      this.bfcConfigurationService.configuration.googleTagManagerContainerId,
    );

    const globalNavElements = document.getElementsByTagName(
      "bfe-global-navigation",
    );
    if (globalNavElements.length !== 1) {
      throw new Error("too many or no global navigation element found");
    }

    let elements = [
      {
        key: "logout",
        titleTranslatable: this.bfcTranslateService.translate("GLOBAL_NAV.LOGOUT"),
        eventId: "logoutEvent",
        icon: "bfc-icon-arrow-right",
      },
    ];
    const serviceItemsModify = JSON.stringify(elements);
    globalNavElements[0].setAttribute("service-items-modify", serviceItemsModify);

    globalNavElements[0].addEventListener("globalNavigationEvent", (event: any) => {
      if (event.detail.eventId === "logoutEvent") {
        this.msalService.logout();
      }
    });
  }
}
