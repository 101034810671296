import { Injectable } from "@angular/core";
import { MsalGuardConfiguration, MsalInterceptorConfiguration } from "@azure/msal-angular";
import { IPublicClientApplication, PublicClientApplication } from "@azure/msal-browser";
import { BfcConfigurationService } from "@bfl/components/configuration";

@Injectable()
export class MSALProvider {
  constructor(private bfcConfigurationService: BfcConfigurationService) {
  }

  initMSALInstanceFactory(): IPublicClientApplication {
    return new PublicClientApplication(this.bfcConfigurationService.configuration.msalConfig);
  }

  initMSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
    // workaround, because new Map() in configuration was recognized only as typeof object from msal
    const config = this.bfcConfigurationService.configuration.msalAngularInterceptorConfig;
    config.protectedResourceMap = new Map<string, Array<string>>(config.protectedResourceMap);
    return config;
  }

  initMSALGuardConfigFactory(): MsalGuardConfiguration {
    return this.bfcConfigurationService.configuration.msalAngularGuardConfig;
  }
}
