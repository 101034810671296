export const FR_TRANSLATIONS = {

  GLOBAL_NAV: {
    LOGOUT: "Logout",
  },

  BFC_UNAUTHORIZED: {
    TITLE: "Accès refusé",
    CONTENT: "Vous ne disposez pas des autorisations requises pour ce service.",
  },
};
