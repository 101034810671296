import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { NgModule } from "@angular/core";
import {
  MsalBroadcastService,
  MsalGuard, MsalInterceptor, MsalModule,
  MsalService, MSAL_GUARD_CONFIG,
  MSAL_INSTANCE,
  MSAL_INTERCEPTOR_CONFIG,
} from "@azure/msal-angular";
import { initMSALGuardConfig, initMSALInstance, initMSALInterceptorConfig } from "./initialization/initializer-factory";
import { MSALProvider } from "./initialization/msal-provider";

// See: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-angular/docs/v2-docs/configuration.md
@NgModule({

  imports: [MsalModule],
  providers: [
    MsalGuard,
    MsalBroadcastService,
    MsalService,
    MSALProvider,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true,
    },
    {
      provide: MSAL_INSTANCE,
      useFactory: initMSALInstance,
      deps: [MSALProvider],
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: initMSALGuardConfig,
      deps: [MSALProvider],
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: initMSALInterceptorConfig,
      deps: [MSALProvider],
    },
  ],
})
export class MsalApplicationModule {

}