import { AfterViewInit, Component, HostBinding, Input, OnDestroy } from "@angular/core";
import { Router } from "@angular/router";
import { BfcTranslationService } from "@bfl/components/translation";
import { Subject } from "rxjs";
import { MediaChange, MediaObserver } from "@angular/flex-layout";
import { BreakpointObserver } from "@angular/cdk/layout";
import { takeUntil } from "rxjs/operators";

@Component({
  selector: "app-side-navigation",
  templateUrl: "./side-navigation.component.html",
  styleUrls: ["./side-navigation.component.scss"],
})
export class SideNavigationComponent implements AfterViewInit, OnDestroy{
  @HostBinding( "class") classes = "bfe-b2b-nav-service-reference";

  private unsubscribe: Subject<void> = new Subject();

  private readonly baseUrl: string = `${window.location.protocol}//${window.location.host}`;

  isSideNavCollapsed = true;

  isMobile = false;

  @Input()
  public url: string;

  readonly navigationTree: any = [
    {
      labelKey: this.translationService.translate("FAN.FP_MENU_TITLE"),
      showIcon: true,
      icon: "bfc-icon-document",
      url: {
        base: this.baseUrl,
        path: "/foto-apparat",
      },
    },
    {
      labelKey: this.translationService.translate("FAN.FB_MENU_TITLE"),
      icon: "bfc-icon-gear",
      url: {
        base: this.baseUrl,
        path: "/foto-browser",
      },
    },
    {
      labelKey: this.translationService.translate("FAN.SETTINGS_MENU_TITLE"),
      icon: "bfc-icon-gear",
      url: {
        base: this.baseUrl,
        path: "/settings",
      },
    },
  ];

  constructor(private router: Router,
    private translationService:BfcTranslationService,
    private breakpointObserver: BreakpointObserver,
    private mediaObserver: MediaObserver,
  ){

  }

  ngAfterViewInit() {
    this.mediaObserver.asObservable().pipe(takeUntil(this.unsubscribe)).subscribe((mediaChanges: MediaChange[]) => {
      this.isMobile = mediaChanges[0]?.mqAlias === "xs";

    });
  }

  toggleSideNav() {
    this.isSideNavCollapsed = !this.isSideNavCollapsed;
  }

  routeRequest(e): void {
    this.router.navigate([e.detail.path]);
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

}
