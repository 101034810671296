import { HttpClientModule } from "@angular/common/http";
import { APP_INITIALIZER, NgModule, Optional, SkipSelf } from "@angular/core";
import { initalizerFactory } from "./initialization/initializer-factory";
import { InitializerProvider } from "./initialization/initializer-provider";
import { throwIfAlreadyLoaded } from "./module-import-guard";
import { BfcGoogleAnalyticsModule } from "@bfl/components/google-analytics";
import { FotoApparatService } from "./foto-apparat.service";
import { DocumentService } from "./document.service";
import { ImageCropperService } from "../main-view/foto-apparat/image-cropper/image-cropper.service";

@NgModule({
  imports: [HttpClientModule, BfcGoogleAnalyticsModule],
  exports: [],
  declarations: [],
  providers: [
    InitializerProvider,
    FotoApparatService,
    DocumentService,
    ImageCropperService,
    {
      provide: APP_INITIALIZER,
      useFactory: initalizerFactory,
      deps: [InitializerProvider],
      multi: true,
    },
  ],
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    throwIfAlreadyLoaded(parentModule, "CoreModule");
  }
}
